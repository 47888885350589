import React, {useState, useEffect, useRef} from 'react'
import Modal from 'react-bootstrap/Modal'
import moment from 'moment'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {ToastContainer, toast} from 'react-toastify'
import clsx from 'clsx'
import {default as Apiservices} from '../../../../../src/common/index'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Container, Row, Col} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import BackDrop from '../../../modules/apps/timesheetentry/ComponentBackdrop'
import EmployeePop from '../../../modules/apps/masters/modal/EmployeePop'
import ButtonCal from '../../../../localMedia/buttonCal.svg'
import {Tooltip} from '../../../modules/apps/timesheetentry/ComponentTooltip'
import {KTSVG} from '../../../../_metronic/helpers'
import {KTCardBody} from '../../../../_metronic/helpers'
import {UsersListPagination} from '../../../modules/apps/user-management/users-list/components/pagination/UsersListPagination'
import Drawer from '../../../modules/apps/masters/modal/ComponentDrawer'
import FinalHoursPop from '../../../modules/apps/masters/modal/FinalHoursPop'

export default function EmpComplianceReports(props: any) {
  const methods = useForm()
  const values = [true]
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [show, setShow] = useState(true)
  const [modalShow, setModalShow] = useState(false)
  const [perPage, setPerPage] = useState(25)
  const [pages, setPages] = useState(1)
  const [page, setPage] = useState(1)
  const [Reportsdata, setReports_Data] = useState<any | []>([])
  const [consultant_type, setConsultantType] = useState('')
  const [selected, setSelected] = useState([])
  const [BusinessUnitsList, setBusinessUnitsList] = useState<any | []>([])
  const [BusinessUnit, setBusinessUnit] = useState<any | []>([])
  const [selectConsultant, setSelectConsultant] = useState([])
  const [order5, setOrder5] = useState('ASC')
  const [order6, setOrder6] = useState('ASC')
  const [order7, setOrder7] = useState('ASC')
  const [order9, setOrder9] = useState('ASC')
  const [sort, setSort] = useState<any | []>(false)
  const [indexState, setindexState] = useState('')
  const [ConsultantName, setConsultantName] = useState('')
  const [indexState1, setindexState1] = useState('')
  const [EachFinalId, setEachFinalId] = useState('')
  const [searchData, setSearchData] = useState<any | []>([])
  const [CommentValidation, setCommentValidation] = useState<any | []>(false)
  const [CommentHistory, setCommentHistory] = useState<any | []>([])
  const [FinalPopOpen, setFinalPopOpen] = useState(false)
  const [AllOptions, setAllOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    {
      label: 'Internal',
      value: 'Internal',
    },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'India Offshore',
      value: 'India Offshore',
    },
  ])
  const handleClose = () => {
    setFullscreen(false)
    setShow(false)
    props.cancel(false)
  }
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required').nullable(),
  })

  type UserSubmitForm = {
    employee_type: string
    access_token: any
    work_city: string
  }
  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema),
  })
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [loading, setLoading] = useState(true)
  const [flag, setFlag] = useState(false)
  const [ReportData, setReportData] = useState<any | []>([])
  const [search, setSearch] = useState('')
  const [test, setTest] = useState<any>(new Date())
  const typeaheadRef: any = useRef<HTMLInputElement>(null)
  const typeaheadRef1: any = useRef<HTMLInputElement>(null)
  let business: any = localStorage.getItem('role_Name')
  let numArray: any = business?.split(',')

  const onSubmit = async (data: UserSubmitForm) => {
    setShowBackDrop(true)
    const employeeValid: any = await Apiservices.employeeId()
    setShowBackDrop(false)
  }

  const onChange = async (e: any) => {
    const {name, value} = e.target
    if (name == 'perPage') {
      setPerPage(value)
      setPage(1)
      handleClick(1, value)
    }
  }

  useEffect(() => {
    get_business_units_list()
    setTest(null)
  }, [])
  const get_business_units_list = async () => {
    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      if (res.data.data.length > 0) {
        // setBusinessUnitsList(res.data.data)
        let obj = {name: 'Select All', value: 'All'}
        setBusinessUnitsList([obj, ...res.data.data])
      }
    }
  }
  const options2: any = [
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    {
      label: 'Internal',
      value: 'Internal',
    },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'India Offshore',
      value: 'India Offshore',
    },
  ]
  const handlePageClick = (event: any) => {
    let page = event.selected
    let selected = page + 1
    setPage(selected)

    handleClick(selected, perPage)
  }

  const handleClick = async (page: any, perPage: any) => {
    // const handleClick = async () => {
    let yesterday = moment(new Date()).subtract(1, 'month').format('M')
    if (test) {
      console.log('if')
    } else {
      setTest(new Date(moment().subtract(1, 'month').format('MMM YYYY')))
    }
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')

    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    setShowBackDrop(true)
    setFlag(true)
    const data: any = await Apiservices.employeeComplianceReport({
      pagenumber: page,
      pagesize: Number(perPage),
      year: test
        ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
        : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
      keyword: search ? search : null,
      month: test ? moment(test, 'ddd MMM DD YYYY').format('M') : Number(yesterday),
      employee_type: stat2 ? stat2 : '',
      business_unit_id: business2 ? business2 : '',
    })
    if (data && data.data && data.data.isError == false) {
      setReportData(data?.data)
      setReports_Data(data?.data?.count)
      setPages(Math.ceil(data.data.count / perPage))
    } else {
      toast.warn('Setting does not exists for given year and month')
      setReportData([])
    }
    setShowBackDrop(false)
  }

  const singleDateSelection = async (date: any) => {
    setTest(date)
  }

  const ref: any = useRef<HTMLInputElement>(null)

  const resetData = async (e: any) => {
    setFlag(false)
    setSearch('')
    setValue('employee_type', '')
    setValue('work_city', '')
    setTest(null)
    // setValue('report_type', '')
    // ref.current.clear()
    setSelectConsultant([])
    setBusinessUnit([])
    typeaheadRef.current.clear()
    typeaheadRef1.current.clear()
    setSelected([])

    let consultantReset = AllOptions.find((item: any) => item.value == 'All')
    if (!consultantReset) {
      AllOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    // AllOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let consultantReset1 = AllOptions.find((item: any) => item.value == 'empty')
    if (consultantReset1) {
      AllOptions.splice(
        AllOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })
    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }
  }
  const handleChange1 = async (value: any) => {
    setSearch(value)
    // setSearch_Open(true)
    setSelectConsultant([])
    setBusinessUnit([])
    let consultantReset = AllOptions.find((item: any) => item.value == 'All')
    if (!consultantReset) {
      AllOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    let consultantReset1 = AllOptions.find((item: any) => item.value == 'empty')
    if (consultantReset1) {
      AllOptions.splice(
        AllOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }
    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }
    let yesterday = moment(new Date()).subtract(1, 'month').format('M')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')

    const data: any = await Apiservices.employeeComplianceReport({
      pagenumber: page,
      pagesize: Number(perPage),
      year: test
        ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
        : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
      month: test ? moment(test, 'ddd MMM DD YYYY').format('M') : Number(yesterday),
      keyword: value,
      employee_type: stat2 ? stat2 : '',
      business_unit_id: business2 ? business2 : '',
    })
    if (data && data.data && data.data.isError == false) {
      setReportData(data?.data)
      setReports_Data(data?.data?.count)
      setPages(Math.ceil(data?.data?.count / Number(perPage)))
    } else {
      toast.warn('Setting does not exists for given year and month')
      setReportData([])
    }
  }
  const exporthandleClick = async (e: any) => {
    let yesterday = moment(new Date()).subtract(1, 'month').format('M')

    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    setShowBackDrop(true)
    const res: any = await Apiservices.employeeExportComplianceReport({
      // year: moment(test, 'ddd MMM DD YYYY').format('YYYY'),
      // month: moment(test, 'ddd MMM DD YYYY').format('M'),

      year: test
        ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
        : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
      month: test ? moment(test, 'ddd MMM DD YYYY').format('M') : Number(yesterday),
      employee_type: stat2 ? stat2 : '',
      business_unit_id: business2 ? business2 : '',
    })
    var link = document.createElement('a')
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', 'Downloaded File')
    link.href = res?.data?.data?.blobUrl
    document.body.appendChild(link)
    link.click()
    link.remove()
    setShowBackDrop(false)
  }
  // let FilteredData = ReportData?.data?.filter(
  //   (each: any) =>
  //     each?.employee_id?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //     each?.employee_type?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //     each?.full_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //     each?.joining_date?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //     each?.leaving_date?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //     each?.emp_working_hours?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //     each?.total_hours?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //     each?.compliance_percentage?.toLowerCase()?.includes(search?.toLowerCase())
  // )

  const handleConsultantType = (selected: any) => {
    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllOptions.filter((temp: any) => temp.value != 'All' && temp.value != 'empty')
          setSelectConsultant(x)
          AllOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllOptions(AllOptions)
          }
        } else if (each.value == 'empty') {
          setSelectConsultant([])
          let cde = AllOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllOptions(AllOptions)
          }
          AllOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectConsultant(selected)
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          let cde = AllOptions.find((item: any) => item.value == 'All')
          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            // setAllOptions(AllOptions)
          }
          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllOptions(AllOptions)
          }
          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectConsultant(selected)
      AllOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let consultantReset = AllOptions.find((item: any) => item.value == 'All')
      if (!consultantReset) {
        AllOptions.unshift({
          label: 'Select All',
          value: 'All',
        })
      }
    }
    // setSelectConsultant(selected)
  }

  const handleBusinessUnit = (selected: any) => {
    // setBusinessUnit(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = BusinessUnitsList.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setBusinessUnit(x)
          BusinessUnitsList.shift({
            name: 'Select All',
            value: 'All',
          })
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
        } else if (each.value == 'empty') {
          setBusinessUnit([])
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList)
          }
          BusinessUnitsList.pop({
            name: 'Deselect All',
            value: 'empty',
          })
        } else {
          setBusinessUnit(selected)
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          let check_drop = selected.find((item: any) => item && item.value && item.value == 'All')
          let check_drop1 = selected.find(
            (item: any) => item && item.value && item.value == 'empty'
          )
          if (!check_drop && !check_drop1) {
            let test = BusinessUnitsList.filter(
              (id: any) => !selected.some((x: any) => x.name == id.name)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              BusinessUnitsList.shift({
                name: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setBusinessUnit(selected)
      BusinessUnitsList.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
      if (!businessReset) {
        BusinessUnitsList.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }

  const sorting5 = (col: any) => {
    if (order5 === 'ASC') {
      const sorted = [...ReportData.data]?.sort((a, b) => (a[col] > b[col] ? 1 : -1))
      let x = {
        ...ReportData,
        data: sorted,
      }

      setReportData(x)
      setOrder5('DSC')
      setSort(false)
    }

    if (order5 === 'DSC') {
      const sorted = [...ReportData.data]?.sort((a, b) => (a[col] < b[col] ? 1 : -1))
      let x = {
        ...ReportData,
        data: sorted,
      }

      setReportData(x)
      setOrder5('ASC')
      setSort(true)
    }
  }

  const sorting6 = (col: any) => {
    if (order6 === 'ASC') {
      const sorted = [...ReportData.data]?.sort((a: any, b: any) => {
        const lowerA = a[col].replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col].replace(/\s+/g, '').toLowerCase()
        return lowerA.localeCompare(lowerB)
      })
      let x = {
        ...ReportData,
        data: sorted,
      }
      setReportData(x)
      setOrder6('DSC')
      setSort(false)
    }

    if (order6 === 'DSC') {
      const sorted = [...ReportData.data]?.sort((a: any, b: any) => {
        const lowerA = a[col].replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col].replace(/\s+/g, '').toLowerCase()
        return lowerB.localeCompare(lowerA)
      })
      let x = {
        ...ReportData,
        data: sorted,
      }
      setReportData(x)
      setOrder6('ASC')
      setSort(true)
    }
  }
  const sorting7 = (col1: any, col2: any) => {
    if (order7 === 'ASC') {
      const sorted = [...ReportData.data]?.sort((a: any, b: any) => {
        let val1 = a[col1] ? a[col1] : a[col2]
        let val2 = b[col1] ? b[col1] : b[col2]
        return val1?.toLowerCase() > val2?.toLowerCase() ? 1 : -1
      })
      let x = {
        ...ReportData,
        data: sorted,
      }
      setReportData(x)
      setOrder7('DSC')
      setSort(false)
    }

    if (order7 === 'DSC') {
      const sorted = [...ReportData.data]?.sort((a: any, b: any) => {
        let val1 = a[col1] ? a[col1] : a[col2]
        let val2 = b[col1] ? b[col1] : b[col2]
        return val1?.toLowerCase() < val2?.toLowerCase() ? 1 : -1
      })
      let x = {
        ...ReportData,
        data: sorted,
      }
      setReportData(x)
      setOrder7('ASC')
      setSort(true)
    }
  }

  const sorting9 = (col: any) => {
    if (order9 === 'ASC') {
      const sorted = [...ReportData.data]?.sort((a, b) => {
        let dateA = new Date(a[col]),
          dateB = new Date(b[col])
        if (dateB > dateA) {
          return -1
        } else {
          return 1
        }
      })
      let x = {
        ...ReportData,
        data: sorted,
      }
      setReportData(x)
      setOrder9('DSC')
      setSort(false)
    }

    if (order9 === 'DSC') {
      const sorted = [...ReportData.data]?.sort((a, b) => {
        let dateA = new Date(a[col]),
          dateB = new Date(b[col])
        if (dateB < dateA) {
          return -1
        } else {
          return 1
        }
      })
      let x = {
        ...ReportData,
        data: sorted,
      }
      setReportData(x)
      setOrder9('ASC')
      setSort(true)
    }
  }
  const getComments = async (id: any) => {
    let yesterday = moment(new Date()).subtract(1, 'month').format('M')

    let month1 = test ? moment(test, 'ddd MMM DD YYYY').format('M') : Number(yesterday)
    let year1 = test
      ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
      : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY')

    const response: any = await Apiservices.getComplianceComments({
      user_id: id,
      month: month1,
      year: year1,
    })
    if (response.data.isError == false) {
      setCommentHistory(response.data.data)
    }
  }
  const handleManageDocumentPop = async (id: any, consultant_name: any) => {
    setindexState(id)
    getComments(id)
    setConsultantName(consultant_name)
    setModalShow(true)
  }
  const commentField = (search: any) => {
    setSearchData(search)
    if (search.length > 0) {
      setCommentValidation(false)
    } else {
      setCommentValidation(true)
    }
  }
  const handleSubmitt = async () => {
    setSearchData([])
    if (searchData.length > 1) {
      setCommentValidation(false)

      let yesterday = moment(new Date()).subtract(1, 'month').format('M')
      let business1 = BusinessUnit.map((val: any) => {
        return val.business_unit_id
      })
      let business2 = business1.filter((temp: any) => `${temp}`).join(',')

      const article = {
        user_id: indexState,
        comments: searchData,
        business_unit_id: business2 ? business2 : '',
        month: test ? moment(test, 'ddd MMM DD YYYY').format('M') : Number(yesterday),
        year: test
          ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
          : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
      }
      const response: any = await Apiservices.insertComplianceComment(article)
      if (response && response.isError == false) {
        toast.success('Commented Successfully')
        getComments(indexState)
        handleClick(page, perPage)
      }
    } else {
      setCommentValidation(true)
    }
  }

  const handleSubmitted = (index: any, id: any) => {
    setFinalPopOpen(false)
    handleFinalHours(index, id, 'handleSubmit')
  }

  const modalPop = () => {
    setFinalPopOpen(false)
  }

  const handleFinalHours = async (index: any, id: any, fromVal: any) => {
    setindexState1(index)
    setEachFinalId(id)

    if (fromVal !== 'handleSubmit') {
      setFinalPopOpen(true)
    } else if (fromVal === 'handleSubmit') {
      let yesterday = moment(new Date()).subtract(1, 'month').format('M')
      let business1 = BusinessUnit.map((val: any) => {
        return val.business_unit_id
      })
      let business2 = business1.filter((temp: any) => `${temp}`).join(',')

      let arr_final = []
      const obj = {is_final_hours: true}
      arr_final.push(obj)

      let final = ReportData.data[index].is_final_hours
      // let final1 = final ? (final[0].is_final_hours = e.target.checked) : arr_final

      // let final1 =
      //   final?.length > 1
      //     ? final[0].is_final_hours
      //       ? (final[0].is_final_hours = e.target.checked)
      //       : (final[0].is_final_hours = e.target.checked)
      //     : arr_final

      let final1 =
        final?.length > 1
          ? final[0].is_final_hours
            ? (final[0].is_final_hours = true)
            : (final[0].is_final_hours = true)
          : arr_final
      ReportData.data[index].is_final_hours = final1

      const article = {
        user_id: id,
        business_unit_id: business2 ? business2 : '',
        month: test ? moment(test, 'ddd MMM DD YYYY').format('M') : Number(yesterday),
        year: test
          ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
          : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
        is_final_hours: true,
      }

      let x = {
        ...ReportData,
      }

      setReportData(x)
      const response: any = await Apiservices.insertComplianceComment(article)
    }
  }
  return (
    <>
      <Drawer
        modalShow={modalShow}
        setModalShow={setModalShow}
        indexState={indexState}
        ConsultantName={ConsultantName}
        commentField={commentField}
        searchData={searchData}
        setCommentValidation={setCommentValidation}
        CommentValidation={CommentValidation}
        setSearchData={setSearchData}
        handleSubmitt={handleSubmitt}
        CommentHistory={CommentHistory}
      />
      <div className='popUpTest'>
        <FinalHoursPop
          FinalPopOpen={FinalPopOpen}
          setFinalPopOpen={setFinalPopOpen}
          handleSubmitted={handleSubmitted}
          modalPop={modalPop}
          indexState1={indexState1}
          EachFinalId={EachFinalId}
          setindexState1={setindexState1}
          setEachFinalId={setEachFinalId}
        />
      </div>
      <Modal show={show} fullscreen={fullscreen} onHide={() => handleClose()}>
        <Modal.Header closeButton className='p-3 bg-light'>
          <Modal.Title>Consultant Compliance Report </Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-3 bg-light'>
          <div className='d-flex justify-content-between align-items-center'>
            <div> </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <BackDrop showBackDrop={showBackDrop} />
            {loading ? (
              <>
                {' '}
                <div className='card card-custom card-flush '>
                  <div className='card-body p-2 py-3'>
                    <div className='row gy-3 align-items-center py-2'>
                      <div className='col-xs-12 col-md-3 col-lg-3'>
                        <label className='form-label fw-semibold'>
                          Select Month <span className='fs-9'>(MMM YYYY)</span> *
                        </label>
                        <DatePicker
                          selected={test}
                          onChange={(date: any) => singleDateSelection(date)}
                          //   customInput={<ExampleCustomInput />}
                          placeholderText='Select Month'
                          className={clsx('form-control')}
                          dateFormat='MMM yyyy'
                          // calendarStartDay={1}
                          showMonthYearPicker
                          // required
                        />
                      </div>
                      {/* <div className='col-xs-12 col-md-3 col-lg-3'>
                        <label className='form-label '>Consultant Type</label>
                        <select
                          // value={selectedItem}
                          className={clsx('form-select', errors.employee_type ? 'is-invalid' : '')}
                          {...register('employee_type')}
                          onChange={(e) => handleChange(e.target.value)}
                        >
                          <option value=''>Select Consultant Type</option>
                          <option value='W2'>W2</option>
                          <option value='C2C'>C2C</option>
                          <option value='Internal'>Internal</option>
                          <option value='1099'>1099</option>
                        </select>
                        <div className='invalid-feedback'>{errors.employee_type?.message}</div>
                      </div> */}

                      <div className='col-xs-12 col-md-3 col-lg-3'>
                        <label className='form-label '>Consultant Type</label>
                        {/* <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(selected: any) => {
                            setSelected(selected)
                            // Keep the menu open when making multiple selections.
                            typeaheadRef.current.toggleMenu()
                          }}
                          options={options2}
                          placeholder='Select Consultant Type'
                          ref={typeaheadRef}
                          selected={selected}
                        /> */}
                        {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}

                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(selected: any) => {
                            handleConsultantType(selected)
                            // setSelectConsultant(selected)
                            // Keep the menu open when making multiple selections.
                            typeaheadRef.current.toggleMenu()
                          }}
                          options={AllOptions}
                          placeholder='Select Consultant Type'
                          ref={typeaheadRef}
                          selected={selectConsultant}
                        />
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Business Unit</label>
                        {/* <Typeahead
                          multiple
                          id='keep-menu-open'
                          // disabled={isView}
                          onChange={(e) => {
                            setBusinessUnit(e)
                            typeaheadRef1.current.toggleMenu()
                          }}
                          options={BusinessUnitsList}
                          ref={typeaheadRef1}
                          placeholder='Select Business Unit'
                          selected={BusinessUnit}
                          labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                          //   inputProps={{required: true}}
                        >
                        
                        </Typeahead> */}
                        {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}

                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          // disabled={isView}
                          onChange={(e) => {
                            // setBusinessUnit(e)
                            handleBusinessUnit(e)
                            typeaheadRef1.current.toggleMenu()
                          }}
                          options={BusinessUnitsList}
                          ref={typeaheadRef1}
                          placeholder='Select Business Unit'
                          selected={BusinessUnit}
                          labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                          //   inputProps={{required: true}}
                        ></Typeahead>
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Search</label>
                        <input
                          type='text'
                          id='form1'
                          className='form-control'
                          // value={search}
                          {...register('work_city')}
                          placeholder='Search'
                          onChange={(e) => handleChange1(e.target.value)}
                        />
                      </div>
                      <div className='col-auto pt-md-0 pt-lg-8'>
                        <button
                          className='btn btn-sm btn-primary'
                          type='submit'
                          onClick={() => handleClick(page, perPage)}
                          // onClick={handleClick}
                        >
                          Run
                        </button>
                      </div>
                      <div className='col-auto pt-md-0 pt-lg-8'>
                        <button
                          type='button'
                          className='btn btn-danger btn-sm cursor-pointer '
                          id='kt_toolbar_primary_button'
                          onClick={resetData}
                        >
                          Reset
                        </button>
                      </div>
                      <div className='col-auto pt-md-0 pt-lg-8'>
                        <button
                          className='btn btn-light-primary   btn-sm'
                          onClick={exporthandleClick}
                        >
                          Export
                          {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <h1>load</h1>
            )}
          </form>
          {flag == false && (
            <div className='fs-6 text-gray-500 text-transform-unset'>
              Note: Please select a month and click run to view the report
            </div>
          )}
          {flag == true && (
            <>
              <div className='pt-3'>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-3 py-3'>
                    <div className='text-gray-900 fs-5 fw-semibold text-transform-unset'>
                      Total Compliance %{' '}
                      <Tooltip text={'(Total Logged Hours/Total Expected Hours)*100'}>
                        <span>
                          <i className='bi bi-info-lg  fs-4 bg-light-danger rounded-circle text-gray-700'></i>
                        </span>
                      </Tooltip>
                      :{' '}
                      {ReportData?.total_compliance_percentage
                        ? ReportData?.total_compliance_percentage
                        : '-'}
                    </div>
                    <KTCardBody className='py-1 '>
                      <div className='pt-3'>
                        <div className='card card-custom card-flush '>
                          <div className='card-body p-3 py-3'>
                            <div className='table-responsive padding-down'>
                              <table
                                id='kt_table_users'
                                className='table table-row-bordered table-row-gray-300 gy-4'
                              >
                                <>
                                  <thead>
                                    <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                                      <th
                                        onClick={() => sorting6('business_unit_name')}
                                        className='  py-4'
                                      >
                                        Business Unit{' '}
                                        <span>
                                          {order6 === 'ASC' ? (
                                            <i
                                              className='bi bi-chevron-down ms-1 '
                                              role='button'
                                            ></i>
                                          ) : (
                                            <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                          )}
                                        </span>
                                      </th>
                                      <th
                                        onClick={() => sorting6('employee_id')}
                                        className='  py-4'
                                      >
                                        Consultant ID{' '}
                                        <span>
                                          {order6 === 'ASC' ? (
                                            <i
                                              className='bi bi-chevron-down ms-1 '
                                              role='button'
                                            ></i>
                                          ) : (
                                            <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                          )}
                                        </span>
                                      </th>
                                      <th
                                        onClick={() => sorting5('employee_type')}
                                        className='  py-4'
                                      >
                                        Consultant Type{' '}
                                        <span>
                                          {order5 === 'ASC' ? (
                                            <i
                                              className='bi bi-chevron-down ms-1 '
                                              role='button'
                                            ></i>
                                          ) : (
                                            <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                          )}
                                        </span>
                                      </th>
                                      <th
                                        onClick={() => sorting7('display_name', 'full_name')}
                                        className='  py-4'
                                      >
                                        Consultant Name{' '}
                                        <span>
                                          {order7 === 'ASC' ? (
                                            <i
                                              className='bi bi-chevron-down ms-1 '
                                              role='button'
                                            ></i>
                                          ) : (
                                            <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                          )}
                                        </span>
                                      </th>
                                      <th
                                        onClick={() => sorting9('joining_date')}
                                        className='  py-4'
                                      >
                                        Joining Date
                                        <span>
                                          {order9 === 'ASC' ? (
                                            <i
                                              className='bi bi-chevron-down ms-1 '
                                              role='button'
                                            ></i>
                                          ) : (
                                            <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                          )}
                                        </span>
                                      </th>
                                      <th
                                        onClick={() => sorting9('leaving_date')}
                                        className='  py-4'
                                      >
                                        Leaving Date
                                        <span>
                                          {order9 === 'ASC' ? (
                                            <i
                                              className='bi bi-chevron-down ms-1 '
                                              role='button'
                                            ></i>
                                          ) : (
                                            <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                          )}
                                        </span>
                                      </th>
                                      <th
                                        onClick={() => sorting5('emp_working_hours')}
                                        className='  py-4'
                                      >
                                        Expected Hours{' '}
                                        <span>
                                          {order5 === 'ASC' ? (
                                            <i
                                              className='bi bi-chevron-down ms-1 '
                                              role='button'
                                            ></i>
                                          ) : (
                                            <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                          )}
                                        </span>
                                      </th>
                                      {/* <th className='  py-4'>Hours Logged</th> */}
                                      <th className='  py-4'></th>
                                      <th className='  py-4' colSpan={3}>
                                        Hours Logged{' '}
                                      </th>
                                      <th className='  py-4'></th>

                                      <th
                                        onClick={() => sorting5('compliance_percentage')}
                                        className='  py-4'
                                      >
                                        Compliance %{' '}
                                        <span>
                                          {order5 === 'ASC' ? (
                                            <i
                                              className='bi bi-chevron-down ms-1 '
                                              role='button'
                                            ></i>
                                          ) : (
                                            <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                          )}
                                        </span>
                                        <Tooltip text={'(Logged Hours/Expected Hours)*100'}>
                                          <span>
                                            <i className='bi bi-info-lg  fs-4 bg-light-danger rounded-circle text-gray-700'></i>
                                          </span>
                                        </Tooltip>
                                      </th>
                                      {numArray?.includes('admin') && (
                                        <>
                                          <th className='  py-4'>Comments </th>
                                          <th className='  py-4'>Final Hours </th>
                                        </>
                                      )}
                                    </tr>
                                    <tr className='fw-semibold fs-7  text-gray-600 text-uppercase'>
                                      <th className=' py-4'></th>
                                      <th className=' py-4'></th>
                                      <th className=' py-4'></th>
                                      <th className=' py-4'></th>
                                      <th className=' py-4'></th>
                                      <th className=' py-4'></th>
                                      <th className=' py-4'></th>

                                      <th
                                        onClick={() => sorting5('total_billed_hours')}
                                        className='   py-4'
                                      >
                                        Billed Hours{' '}
                                        <span>
                                          {order5 === 'ASC' ? (
                                            <i
                                              className='bi bi-chevron-down ms-1 '
                                              role='button'
                                            ></i>
                                          ) : (
                                            <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                          )}
                                        </span>
                                      </th>
                                      <th
                                        onClick={() => sorting5('total_bench_hours')}
                                        className='   py-4'
                                      >
                                        Bench Hours{' '}
                                        <span>
                                          {order5 === 'ASC' ? (
                                            <i
                                              className='bi bi-chevron-down ms-1 '
                                              role='button'
                                            ></i>
                                          ) : (
                                            <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                          )}
                                        </span>
                                      </th>
                                      <th
                                        onClick={() => sorting5('total_leave_hours')}
                                        className='   py-4'
                                      >
                                        Leave Hours{' '}
                                        <span>
                                          {order5 === 'ASC' ? (
                                            <i
                                              className='bi bi-chevron-down ms-1 '
                                              role='button'
                                            ></i>
                                          ) : (
                                            <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                          )}
                                        </span>
                                      </th>
                                      <th
                                        onClick={() => sorting5('total_hours')}
                                        className='   py-4'
                                      >
                                        Total Hours{' '}
                                        <span>
                                          {order5 === 'ASC' ? (
                                            <i
                                              className='bi bi-chevron-down ms-1 '
                                              role='button'
                                            ></i>
                                          ) : (
                                            <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                          )}
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {ReportData?.data?.length > 0 ? (
                                      <>
                                        {ReportData?.data?.map((each: any, index: any) => {
                                          return (
                                            <tr
                                              className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                              key={index}
                                            >
                                              <td className=''>
                                                {each.business_unit_name
                                                  ? each.business_unit_name
                                                  : '-'}
                                              </td>
                                              <td className=''>
                                                {each.employee_id ? each.employee_id : '-'}
                                              </td>
                                              <td className=''>
                                                {each.employee_type ? each.employee_type : '-'}
                                              </td>
                                              <td className='text-capitalize'>
                                                {each.display_name
                                                  ? each.display_name
                                                  : each.full_name
                                                  ? each.full_name
                                                  : '-'}
                                              </td>

                                              <td className=''>
                                                {each.joining_date
                                                  ? moment(each.joining_date).format('DD-MMM-YYYY')
                                                  : '-'}
                                              </td>
                                              <td className=''>
                                                {each.leaving_date
                                                  ? moment(each.leaving_date).format('DD-MMM-YYYY')
                                                  : '-'}
                                              </td>
                                              {/* <td className=''>
                                            {each.emp_working_hours
                                              ? each.emp_working_hours <= 9
                                                ? '0' + each.emp_working_hours
                                                : each.emp_working_hours
                                              : '00'}
                                            :{'00'}
                                          </td> */}
                                              <td className=''>
                                                {each.emp_working_hours
                                                  ? each.emp_working_hours.toFixed(2)
                                                  : '0.00'}
                                              </td>
                                              {/* <td className=''>{each.total_hours}</td> */}
                                              {/* <td className=''>
                                            {each.total_hours <= 9
                                              ? '0' + each.total_hours
                                              : each.total_hours}
                                            :{'00'}{' '}
                                          </td> */}
                                              {/* <td className=''>
                                            {each.total_hours ? each.total_hours.toFixed(2) : '-'}
                                          </td> */}
                                              <td className=''>
                                                {each.total_billed_hours
                                                  ? each.total_billed_hours.toFixed(2)
                                                  : '-'}
                                              </td>
                                              <td className=''>
                                                {each.total_bench_hours
                                                  ? each.total_bench_hours.toFixed(2)
                                                  : '-'}
                                              </td>
                                              <td className=''>
                                                {each.total_leave_hours
                                                  ? each.total_leave_hours.toFixed(2)
                                                  : '-'}
                                              </td>
                                              <td className=''>
                                                {each.total_hours
                                                  ? each.total_hours.toFixed(2)
                                                  : '-'}
                                              </td>
                                              <td></td>
                                              <td className=''>{each.compliance_percentage}</td>
                                              {numArray?.includes('admin') && (
                                                <>
                                                  <td className=''>
                                                    <a
                                                      onClick={() =>
                                                        handleManageDocumentPop(
                                                          each.id,
                                                          each.display_name
                                                        )
                                                      }
                                                      className='position-relative btn btn-icon btn-light-primary btn-sm me-1'
                                                    >
                                                      <KTSVG
                                                        path='/media/icons/duotune/communication/com007.svg'
                                                        className='svg-icon-2'
                                                      />
                                                      {each.total_compliance_comments[0].count ? (
                                                        <span className='position-absolute top-0 start-100 translate-middle p-2 bg-light-info border border-light rounded-circle'>
                                                          <span>
                                                            {
                                                              each.total_compliance_comments[0]
                                                                .count
                                                            }
                                                          </span>
                                                        </span>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </a>
                                                  </td>
                                                  <td className=''>
                                                    <div
                                                      onChange={(e) =>
                                                        handleFinalHours(index, each.id, '')
                                                      }
                                                      className='form-check'
                                                    >
                                                      <input
                                                        className='form-check-input tableCheckBox '
                                                        disabled={
                                                          each.is_final_hours &&
                                                          each.is_final_hours[0] &&
                                                          each.is_final_hours[0].is_final_hours
                                                            ? true
                                                            : false
                                                        }
                                                        type='checkbox'
                                                        id='flexCheckDefault'
                                                        checked={
                                                          each.is_final_hours &&
                                                          each.is_final_hours[0] &&
                                                          each.is_final_hours[0].is_final_hours
                                                            ? true
                                                            : false
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                </>
                                              )}
                                            </tr>
                                          )
                                        })}
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </tbody>
                                </>
                              </table>
                              {(ReportData?.data?.length == null ||
                                ReportData?.data?.length == 0) && (
                                <div className='text-center  fw-bold text-gray-800'>
                                  No Records found{' '}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <UsersListPagination />
                    </KTCardBody>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-stack py-2 pr-12'>
                {ReportData?.data?.length > 0 ? (
                  <div>
                    <Container className='p-0'>
                      <Row className='align-items-center'>
                        <Col xs='auto' className='pe-0'>
                          {' '}
                          <label className='form-label fw-semibold'>Rows Per Page</label>
                        </Col>

                        <Col>
                          <select
                            name='perPage'
                            className='form-select'
                            onChange={onChange}
                            value={perPage}
                          >
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='75'>75</option>
                            <option value={Reportsdata}>All</option>
                          </select>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                ) : null}

                <div className='pr-150'>
                  {ReportData?.data?.length > 0 ? (
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      pageCount={pages}
                      onPageChange={handlePageClick}
                      containerClassName={'pagination justify-content-right'}
                      activeClassName={'active'}
                      breakLabel={'...'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                    />
                  ) : null}
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
