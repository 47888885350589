import React, {useState, useEffect, useRef} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import moment from 'moment'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {ToastContainer, toast} from 'react-toastify'
import clsx from 'clsx'
import {default as Apiservices} from '../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {useLocation} from 'react-router-dom'
import EmployeePop from '../../../modules/apps/masters/modal/EmployeePop'
// import BackDrop from '../../../modules/apps/timesheetentry/ComponentBackdrop'
import BackDrop from '../../../modules/apps/timesheetentry/ComponentBackdrop'
import {devNull} from 'os'
import {start} from '@popperjs/core'
import {KTCardBody} from '../../../../_metronic/helpers'
import {UsersListPagination} from '../../../modules/apps/user-management/users-list/components/pagination/UsersListPagination'
import {Container, Row, Col} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'

export default function TimeSheetSubmissionReports(props: any) {
  const methods = useForm()
  const values = [true]
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [show, setShow] = useState(true)
  const [dates, setDates] = useState<any>([])
  const [mainTableData, setMainTableData] = React.useState<any | []>([])
  const [mainTableDataCount, setMainTableDataCount] = React.useState<any | []>([])
  const [allTableData, setAllTableData] = React.useState([])
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [isView, setIsView] = useState(false)
  const [country, setCountry] = useState<any | []>([])
  const [country1, setCountry1] = useState<any | []>([])
  const [filteredMatch, setFilteredMatch] = useState<any>()
  const [filteredMatch1, setFilteredMatch1] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [flag, setFlag] = useState(false)
  const [flag1, setFlag1] = useState(false)
  const [countries, setCountries] = useState<any | []>([])
  const [useStateCountryId, setUseStateCountryId] = useState<any | []>([])
  const [countries1, setCountries1] = useState<any | []>([])
  const [useStateCountryId1, setUseStateCountryId1] = useState<any | []>([])
  const [useStateCountryId2, setUseStateCountryId2] = useState<any | []>([])
  const [statesId, setStatesId] = useState<any | []>([])
  const [selectedItem, setSelectedItem] = useState('')
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [resetData1, setResetData] = useState<any | []>([])
  const [employeeName, setEmployeeName] = useState<any>()
  const [selectedStatus, setSelectedStatus] = useState('')
  const [value1, setValue1] = useState<any>([])
  const [value2, setValue2] = useState<any>([])
  const [date, setDate] = useState(new Date())
  const [projectsData, setProjectsData] = useState<any | []>([])
  const [indexState, setindexState] = useState('')
  const [totalState, setTotalState] = useState('')
  const [search, setSearch] = useState('')
  const [BusinessUnit, setBusinessUnit] = useState<any | []>([])
  const [candidateList1, setCandidateList1] = useState<any | []>([])
  const [select_consultant, setSelectconsultant] = useState<any | []>([])
  const [order5, setOrder5] = useState('ASC')
  const [order6, setOrder6] = useState('ASC')
  const [sort, setSort] = useState<any | []>(false)
  const [announcement_open_popup, setAnnouncementOpenPoup] = useState(false)
  const [perPage, setPerPage] = useState(25)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [search_open, setSearch_Open] = useState(false)
  const [BusinessUnitsList, setBusinessUnitsList] = useState<any | []>([])
  const typeaheadRef4: any = useRef<HTMLInputElement>(null)
  const typeaheadRef5: any = useRef<HTMLInputElement>(null)
  const typeaheadRef6: any = useRef<HTMLInputElement>(null)
  const [AllProjectStatusOptions, setAllProjectStatusOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'In Progress',
      value: 'IN_PROGRESS',
    },
    {
      label: 'Initiated',
      value: 'INITIATED',
    },
    {
      label: 'Completed',
      value: 'COMPLETED',
    },
    {
      label: 'Cancelled',
      value: 'CANCELLED',
    },
    {
      label: 'Rejected',
      value: 'REJECTED',
    },
  ])
  const [selectProjectStatus, setSelectProjectStatus] = useState<any>([])

  var startingDate = moment(startDate, ' DD/MM/YYYY').format('DD-MMM-YYYY')
  var endingDate = moment(endDate, ' DD/MM/YYYY').format('DD-MMM-YYYY')

  const [account, setAccount] = useState({
    joining_date: '',
    leaving_date: '',
    // enddate: "",
    reason: '',
    leavetype: '',
  })

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  function handleShow(breakpoint: any) {
    setFullscreen(true)
    setShow(true)
  }

  const handleClose = () => {
    setFullscreen(false)
    setShow(false)
    props.cancel(false)
    props.view_open(false)
    props.setViewOpen(false)
  }

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required').nullable(),
  })

  type UserSubmitForm = {
    joining_date: Date
    work_country: string
    profileIdResponse: any
    leaving_date: string
    timesheet_approver: string
    searchcandidate: string
    qb_employee_name: string
    work_city: string
    qb_employee_id: number
    prefix: string
    record_type_status: string
    consultant_type: string
    first_name: string
    name: string
    phone_number: string
    placement_type: string
    phone: string
    maxLength: any
    // adp_associate_id: number
    last_name: string
    project_name: string
    user_status: string
    employeeId: any
    toolName: any
    orgId: any
    domain: string
    roleName: any
    org_name: any
    userStatus: any
    ecdb_user_id: number
    role: string
    email: string
    firstName: string
    full_name: string
    employee_type: string
    // user_status: string
    adp_associate_id: string
    status: string
    userId: any
    filteredMatch: any
    id: any
    employee_id: any
    candidateId: any
    org_id: any
    profileResponse: any
    work_state: string
    zipcode: string
    permanent_address: string
    work_location: string
    work_street_address: string
    work_address: string
    business_unit_id: any
  }

  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema),
  })

  const ref: any = useRef<HTMLInputElement>(null)
  const ref1: any = useRef<HTMLInputElement>(null)

  const SuccessToast = (message: any) => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 2000,
    })
  }

  const handleChange44 = (e: any) => {
    const name = e.target.name
    const value = e.target.value
    setFilteredMatch(e.target.value)
    setFilteredMatch1(e.target.value)
    setAccount({...account, [name]: value})
  }
  const user = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''

      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })

      return str
    }
  }
  const ErrorToast = (message: any) => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    })
  }
  useEffect(() => {
    get_business_units_list()
  }, [])
  const get_business_units_list = async () => {
    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      if (res.data.data.length > 0) {
        // setBusinessUnitsList(res.data.data)
        let obj = {name: 'Select All', value: 'All'}
        setBusinessUnitsList([obj, ...res.data.data])
      }
    }
  }
  const selectedCountries = async (state: any) => {
    setFlag1(false)
    setCountries(state)
    setUseStateCountryId(state[0]?.id)
    // setResetData(state[0]?.full_name)
  }

  const handleCountries = async (country: any) => {
    if (country && country.length >= 2) {
      const response: any = await Apiservices.getAllEmployesData({country_name: country})
      setCountry(response && response.data && response.data.data ? response?.data?.data : '')
    }
  }

  const handleFilterConsultant1 = async (consult_Name: any) => {
    const response: any = await Apiservices.getAllEmployesData({country_name: consult_Name})
    setCandidateList1(response?.data?.data)
  }
  const selectedConsultant1 = async (consult_name: any) => {
    setFlag1(false)
    setSelectconsultant(consult_name)
  }
  const selectedCountries1 = async (state: any) => {
    setFlag1(false)
    setCountries1(state)
    setUseStateCountryId1(state[0]?.project_name)
    setUseStateCountryId2(state[0]?.id)
  }

  const handleCountries1 = async (country1: any) => {
    if (country1 && country1.length >= 2) {
      const response: any = await Apiservices.getprojectbyname({country_name: country1})
      if (response.data.data !== null) {
        setCountry1(response && response.data && response.data.data ? response?.data?.data : '')
      }
    }
  }

  const userName = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''
      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })
      return str
    }
  }

  function isValidEmail(email: any) {
    return /\S+@\S+\.\S+/.test(email)
  }

  const handleChange6 = (event: any) => {
    setEmployeeName(event.target.value)
  }

  const onSubmit = async (data: UserSubmitForm) => {
    setShowBackDrop(true)
    const employeeValid: any = await Apiservices.employeeId()
    let employeeIdData = employeeValid.data.data.employee_id
    setShowBackDrop(false)
  }

  const handleChange1 = (e: any) => {
    setSelectedStatus(e.target.value)
  }

  const onChangeDate = (e: any) => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD')
    setValue1(newDate)
    props.setFilterDepartment(newDate)
  }

  const onChangeDate1 = (e: any) => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD')
    setValue2(newDate)
    props.setFilterStatus(newDate)
  }

  const handlePageClick = (event: any) => {
    let page = event.selected
    let selected = page + 1
    setPage(selected)
    // getallprojects(mainTableData, selected, perPage)
    handleClick(selected, perPage)
  }

  const onChange = async (e: any) => {
    const {name, value} = e.target
    if (name == 'perPage') {
      setPerPage(value)
      setPage(1)
      // getallprojects(mainTableData, 1, value)
      handleClick(1, value)
    }
  }

  // const handleClick = async (e: any) => {
  const handleClick = async (page: any, perPage: any) => {
    setSearch_Open(false)
    setShowBackDrop(true)
    let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')

    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let project_status1 = selectProjectStatus.map((temp: any) => {
      return temp.value
    })
    let project_status2 = project_status1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    setFlag(true)
    if (flag1) {
      const data: any = await Apiservices.employeereportViewDataProject({
        // pagenumber: currentPage,
        // pagesize: itemsPerPage,
        start_date: startDate2,
        end_date: endDate2,
        employee_type: selectedItem ? selectedItem : null,
        project_status: project_status2 ? project_status2 : null,
        business_unit_id: business2 ? business2 : null,
        // user_id: useStateCountryId,
        // project_id: useStateCountryId1,
        pagenumber: page,
        pagesize: perPage,
      })
      setMainTableData(data?.data?.data)
      setMainTableDataCount(data?.data?.count)
      setPages(Math.ceil(data?.data?.count / Number(perPage)))
      if (
        data &&
        data.data &&
        data.data.data &&
        data.data.data[0] &&
        data.data.data[0].timesheet_data
      ) {
        setAllTableData(data?.data?.data[0].timesheet_data)
      }
    } else {
      const data: any = await Apiservices.employeereportViewDataProject({
        // pagenumber: currentPage,
        // pagesize: itemsPerPage,
        start_date: startDate2,
        end_date: endDate2,
        employee_type: selectedItem ? selectedItem : null,
        business_unit_id: business2 ? business2 : null,
        user_id: employee1 ? employee1 : null,
        // project_id: 44,
        project_status: project_status2 ? project_status2 : null,
        pagenumber: page,
        pagesize: perPage,
      })
      setMainTableData(data?.data?.data)
      setMainTableDataCount(data?.data?.count)
      setPages(Math.ceil(data?.data?.count / Number(perPage)))
      if (
        data &&
        data.data &&
        data.data.data &&
        data.data.data[0] &&
        data.data.data[0].timesheet_data
      ) {
        setAllTableData(data?.data?.data[0]?.timesheet_data)
      }
    }
    setShowBackDrop(false)
  }

  const handleChange = (e: any) => {
    setSelectedItem(e.target.value)
  }

  const handleDescription = async (description: any, total: any, index: any) => {
    var today = moment(date)
    var startOfWeek = today.clone().startOf('isoWeek').format('YYYY-MM-DD')
    var endOfWeek = today.clone().endOf('isoWeek').format('YYYY-MM-DD')
    const res: any = await Apiservices.employeeWiseProject({
      // start_date: startOfWeek,
      // end_date: endOfWeek,
      // user_id: description,
      // employee_type: selectedItem,
      start_date: startDate,
      end_date: endDate,
      employee_type: selectedItem == '' ? null : selectedItem,
      user_id: description,
      project_id: useStateCountryId2 == '' ? null : useStateCountryId2,
      project_status: selectedStatus == '' ? 'null' : selectedStatus,
    })
    setProjectsData(res.data.data)
    setAnnouncementOpenPoup(true)
    setindexState(description)
    setTotalState(total)
  }

  const AnnouncePop = (value: any) => {
    setAnnouncementOpenPoup(false)
  }

  const resetData = async (e: any) => {
    setFlag(false)
    setFlag1(true)
    setDateRange([null])
    setValue('placement_type', '')
    setValue('work_city', '')
    setValue('business_unit_id', '')
    setUseStateCountryId([])
    setSelectconsultant([])
    setBusinessUnit([])
    setSelectProjectStatus([])

    setSelectedItem('')
    setSelectedStatus('')
    // ref.current.clear()
    // ref1.current.clear()

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })
    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let projectStatusReset = AllProjectStatusOptions.find((item: any) => item.value == 'All')
    if (!projectStatusReset) {
      AllProjectStatusOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    // AllProjectStatusOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let projectStatusReset1 = AllProjectStatusOptions.find((item: any) => item.value == 'empty')
    if (projectStatusReset1) {
      AllProjectStatusOptions.splice(
        AllProjectStatusOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }
    // const data: any = await Apiservices.employeereportViewData({
    //   // pagenumber: currentPage,
    //   // pagesize: itemsPerPage,
    //   start_date: dates,
    //   end_date: dates1,
    // })
    //     setMainTableData(data?.data?.data)
  }

  const searchHandleChange = async (value: any) => {
    setSearch_Open(true)
    setSearch(value)
    setSelectconsultant([])
    setValue('placement_type', '')
    setValue('business_unit_id', '')
    setSelectedItem('')
    setSelectedStatus('')
    setUseStateCountryId([])
    setBusinessUnit([])
    setSelectProjectStatus([])
    // ref.current.clear()
    // ref1.current.clear()
    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })
    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let projectStatusReset = AllProjectStatusOptions.find((item: any) => item.value == 'All')
    if (!projectStatusReset) {
      AllProjectStatusOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    // AllProjectStatusOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let projectStatusReset1 = AllProjectStatusOptions.find((item: any) => item.value == 'empty')
    if (projectStatusReset1) {
      AllProjectStatusOptions.splice(
        AllProjectStatusOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    const data: any = await Apiservices.employeereportViewDataProject({
      // pagenumber: currentPage,
      // pagesize: itemsPerPage,
      start_date: startDate2,
      end_date: endDate2,
      keyword: value,
      pagenumber: page,
      pagesize: perPage,
    })
    setMainTableData(data?.data?.data)
    setMainTableDataCount(data?.data?.count)
    setPages(Math.ceil(data?.data?.count / Number(perPage)))
  }
  const handleBusinessUnit = (selected: any) => {
    // setBusinessUnit(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = BusinessUnitsList.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setBusinessUnit(x)
          BusinessUnitsList.shift({
            name: 'Select All',
            value: 'All',
          })
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
        } else if (each.value == 'empty') {
          setBusinessUnit([])
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList)
          }
          BusinessUnitsList.pop({
            name: 'Deselect All',
            value: 'empty',
          })
        } else {
          setBusinessUnit(selected)
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          let check_drop = selected.find((item: any) => item && item.value && item.value == 'All')
          let check_drop1 = selected.find(
            (item: any) => item && item.value && item.value == 'empty'
          )
          if (!check_drop && !check_drop1) {
            let test = BusinessUnitsList.filter(
              (id: any) => !selected.some((x: any) => x.name == id.name)
            )
            if (test.length == 2) {
              console.log('length')

              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              BusinessUnitsList.shift({
                name: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setBusinessUnit(selected)
      BusinessUnitsList.pop({
        label: 'Deselect All',
        value: 'empty',
      })
    }
  }

  const handleProjectStatus = (selected: any) => {
    // setSelectProjectStatus(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllProjectStatusOptions.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setSelectProjectStatus(x)
          AllProjectStatusOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllProjectStatusOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllProjectStatusOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectStatusOptions(AllProjectStatusOptions)
          }
        } else if (each.value == 'empty') {
          setSelectProjectStatus([])
          let cde = AllProjectStatusOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllProjectStatusOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllProjectStatusOptions(AllProjectStatusOptions)
          }
          AllProjectStatusOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectProjectStatus(selected)
          let abc = AllProjectStatusOptions.find((item: any) => item.value == 'empty')
          let cde = AllProjectStatusOptions.find((item: any) => item.value == 'All')

          if (!abc) {
            AllProjectStatusOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectStatusOptions(AllProjectStatusOptions)
          }
          if (!cde) {
            AllProjectStatusOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllProjectStatusOptions(AllProjectStatusOptions)
          }

          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllProjectStatusOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllProjectStatusOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectProjectStatus(selected)
      AllProjectStatusOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })
    }
  }
  // const exporthandleClick = async (e: any) => {
  //   setShowBackDrop(true)
  //   // var today = moment(date)
  //   // var startOfWeek = today.clone().startOf('isoWeek').format('YYYY-MM-DD')
  //   // var endOfWeek = today.clone().endOf('isoWeek').format('YYYY-MM-DD')
  //   let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
  //   let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
  //   // e.preventDefault();
  //   // if (flag3 == true) {
  //   const res: any = await Apiservices.employeeExportreportViewData1({
  //     // start_date: startDate,
  //     // end_date: endDate,
  //     // start_date: startDate2,
  //     // end_date: endDate2,
  //     // employee_type: selectedItem == "" ? null : selectedItem,
  //     // user_id: useStateCountryId == null ? null : useStateCountryId,
  //     // project_id: useStateCountryId2 == "" ? null : useStateCountryId2,
  //     // project_status: selectedStatus == "" ? "null" : selectedStatus,
  //     start_date: startDate2,
  //     end_date: endDate2,
  //     employee_type: selectedItem == '' ? null : selectedItem,
  //     user_id: useStateCountryId,
  //     // project_id: 44,
  //     project_status: selectedStatus == '' ? null : selectedStatus,
  //     keyword: search ? search : '',
  //   })
  //   setShowBackDrop(false)
  //   var link = document.createElement('a')
  //   // If you don't know the name or want to use
  //   // the webserver default set name = ''
  //   link.setAttribute('download', 'Downloaded File')
  //   link.href = res.data.data.blobUrl
  //   document.body.appendChild(link)
  //   link.click()
  //   link.remove()
  //   // }
  // }

  const exporthandleClick = async (e: any) => {
    setShowBackDrop(true)
    let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let project_status1 = selectProjectStatus.map((temp: any) => {
      return temp.value
    })
    let project_status2 = project_status1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    if (search_open) {
      // if (flag3 == true) {
      const res: any = await Apiservices.employeeExportreportViewData1({
        // employee_type: selectedItem == "" ? null : selectedItem,
        // user_id: useStateCountryId == null ? null : useStateCountryId,
        // project_id: useStateCountryId2 == "" ? null : useStateCountryId2,
        // project_status: selectedStatus == "" ? "null" : selectedStatus,
        start_date: startDate2,
        end_date: endDate2,
        // employee_type: selectedItem == '' ? null : selectedItem,
        // user_id: useStateCountryId,
        // project_status: selectedStatus == '' ? null : selectedStatus,
        keyword: search ? search : '',
        business_unit_id: business2 ? business2 : null,
      })
      var link = document.createElement('a')
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', 'Downloaded File')
      link.href = res.data.data.blobUrl
      document.body.appendChild(link)
      link.click()
      link.remove()
    } else {
      // if (flag3 == true) {
      const res: any = await Apiservices.employeeExportreportViewData1({
        // employee_type: selectedItem == "" ? null : selectedItem,
        // user_id: useStateCountryId == null ? null : useStateCountryId,
        // project_id: useStateCountryId2 == "" ? null : useStateCountryId2,
        // project_status: selectedStatus == "" ? "null" : selectedStatus,
        start_date: startDate2,
        end_date: endDate2,
        employee_type: selectedItem == '' ? null : selectedItem,
        user_id: employee1 ? employee1 : null,
        project_status: project_status2 ? project_status2 : null,
        business_unit_id: business2 ? business2 : null,
        // keyword: search ? search : '',
      })
      var link = document.createElement('a')
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', 'Downloaded File')
      link.href = res.data.data.blobUrl
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
    setShowBackDrop(false)
  }

  const getMaxDate = (date: any) => {
    if (!date) {
      return date
    }
    // const startTime = new Date(date).getTime()
    const startTime = moment(date).add(30, 'd').format('YYYY-MM-DD')
    // const month = date.getMonth()
    // const year = date.getFullYear()
    // const daysInMonth = new Date(year, month, 0).getDate()
    return new Date(startTime)
  }

  const maxDate = React.useMemo(() => getMaxDate(dateRange[0]), [dateRange])

  const sorting5 = (col: any) => {
    console.log(col, '5', typeof col)
    if (order5 === 'ASC') {
      const sorted = [...mainTableData]?.sort((a, b) =>
        a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1
      )
      console.log(sorted, 'asc', typeof sorted)

      setMainTableData(sorted)
      setOrder5('DSC')
      setSort(false)
    }

    if (order5 === 'DSC') {
      const sorted = [...mainTableData]?.sort((a, b) =>
        a[col]?.toLowerCase() < b[col]?.toLowerCase() ? 1 : -1
      )
      console.log(sorted, 'dsc', typeof sorted)

      setMainTableData(sorted)
      setOrder5('ASC')
      setSort(true)
    }
  }

  const sorting6 = (col: any, col2: any, col3: any) => {
    console.log(col, col2, col3)
    if (order6 === 'ASC') {
      const sorted = [...mainTableData]?.sort((a, b) =>
        parseFloat(a[col][col2][col3]) > parseFloat(b[col][col2][col3]) ? 1 : -1
      )
      console.log(sorted)
      setMainTableData(sorted)
      setOrder6('DSC')
      setSort(false)
    }

    if (order6 === 'DSC') {
      console.log(col, col2, col3)

      const sorted = [...mainTableData]?.sort((a, b) =>
        parseFloat(a[col][col2][col3]) < parseFloat(b[col][col2][col3]) ? 1 : -1
      )
      console.log(sorted)

      setMainTableData(sorted)
      setOrder6('ASC')
      setSort(true)
    }
  }

  return (
    <>
      {announcement_open_popup ? (
        <EmployeePop
          close={AnnouncePop}
          indexState={indexState}
          totalState={totalState}
          role_name={'Admin'}
          setProjectsData={setProjectsData}
          projectsData={projectsData}
        />
      ) : (
        ''
      )}
      <Modal show={show} fullscreen={fullscreen} onHide={() => handleClose()}>
        <Modal.Header closeButton className='p-3 bg-light'>
          <Modal.Title>
            TimeSheet Report (Total No.of hours submitted by consultants - Day wise)
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-3 bg-light'>
          <form onSubmit={handleSubmit(onSubmit)}>
            {loading ? (
              <>
                <BackDrop showBackDrop={showBackDrop} />
                <div className='card card-custom card-flush '>
                  <div className='card-body p-2 py-3'>
                    <div className='row gy-3 align-items-center py-2'>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label fw-semibold'>
                          Date Range <span className='fs-9'>(DD-MMM-YYYY)</span> *
                        </label>
                        <DatePicker
                          placeholderText='Date Range'
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          dateFormat='dd-MMM-yyyy'
                          onChange={(update: any) => {
                            setDateRange(update)
                          }}
                          minDate={startDate}
                          maxDate={maxDate}
                          isClearable={true}
                          monthsShown={2}
                          className={clsx('form-control')}
                        />
                      </div>

                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Consultant Name</label>
                        {/* <Typeahead
                          id='basic-typeahead-single'
                          disabled={isView}
                          {...register('work_country')}
                          onChange={(e) => selectedCountries(e)}
                          onInputChange={(e) => handleCountries(e)}
                          placeholder='Please Enter Employee Name'
                          options={country}
                          // selected={countries}
                          labelKey={(country: any) => user(country.full_name)}
                          ref={ref}
                        >
                          {({onClear, selected}) => (
                            <div className='rbt-aux'>
                              {!!selected.length && <ClearButton onClick={onClear} />}
                            </div>
                          )}
                        </Typeahead>
                        <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(e) => {
                            selectedConsultant1(e)
                            typeaheadRef5.current.toggleMenu()
                          }}
                          onInputChange={(e) => handleFilterConsultant1(e)}
                          options={candidateList1}
                          ref={typeaheadRef5}
                          placeholder='Search Consultant'
                          selected={select_consultant}
                          labelKey={(candidateList1: any) => user(candidateList1.full_name)}
                        ></Typeahead>
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Business Unit</label>
                        {/* <select
                          // value={selectedItem}
                          className={clsx('form-select', errors.employee_type ? 'is-invalid' : '')}
                          {...register('business_unit_id')}
                          onChange={(e: any) => setValue('business_unit_id', e.target.value)}
                        >
                          <option value=''>Select Business Unit</option>
                          {BusinessUnitsList &&
                            BusinessUnitsList.length > 0 &&
                            BusinessUnitsList.map((option: any) => {
                              return (
                                <option value={option.business_unit_id} className='text-capitalize'>
                                  {option.name}
                                </option>
                              )
                            })}
                        </select>
                        <div className='invalid-feedback'>{errors.first_name?.message}</div> */}
                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          // disabled={isView}
                          onChange={(e) => {
                            // setBusinessUnit(e)
                            handleBusinessUnit(e)
                            typeaheadRef4.current.toggleMenu()
                          }}
                          options={BusinessUnitsList}
                          ref={typeaheadRef4}
                          placeholder='Select Business Unit'
                          selected={BusinessUnit}
                          labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                          //   inputProps={{required: true}}
                        ></Typeahead>
                      </div>

                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Project Status </label>
                        {/* <select
                          value={selectedStatus}
                          className={clsx('form-select', errors.employee_type ? 'is-invalid' : '')}
                          {...register('employee_type')}
                          onChange={handleChange1}
                        >
                          <option value=''>Select Project Status</option>
                          <option value='IN_PROGRESS'>In Progress</option>
                          <option value='INITIATED'>Initiated</option>
                          <option value='COMPLETED'>Completed</option>
                          <option value='CANCELLED'>Cancelled</option>
                        </select>
                        <div className='invalid-feedback'>{errors.user_status?.message}</div> */}
                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(selected: any) => {
                            // setSelectProjectStatus(selected)
                            handleProjectStatus(selected)
                            // Keep the menu open when making multiple selections.
                            typeaheadRef6.current.toggleMenu()
                          }}
                          options={AllProjectStatusOptions}
                          placeholder='Select Project Status'
                          ref={typeaheadRef6}
                          selected={selectProjectStatus}
                        />
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Search</label>
                        <input
                          type='text'
                          id='form1'
                          className='form-control'
                          {...register('work_city')}
                          placeholder='Search'
                          onChange={(e) => searchHandleChange(e.target.value)}
                        />
                        <div className='invalid-feedback'>{errors.last_name?.message}</div>
                      </div>
                      <div className='col-auto pt-md-0 pt-lg-8'>
                        <button
                          className='btn btn-sm btn-primary'
                          // onClick={handleClick}
                          onClick={() => handleClick(page, perPage)}
                        >
                          Run
                        </button>
                      </div>
                      <div className='col-auto pt-md-0 pt-lg-8'>
                        <button
                          type='button'
                          className='btn btn-danger btn-sm cursor-pointer '
                          id='kt_toolbar_primary_button'
                          onClick={resetData}
                        >
                          Reset
                        </button>
                      </div>
                      <div className='col-auto pt-md-0 pt-lg-8'>
                        <button
                          // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                          className='btn btn-light-primary   btn-sm'
                          onClick={exporthandleClick}
                        >
                          {' '}
                          Export
                          {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <h1>load</h1>
            )}
          </form>
          {flag == false && (
            <div className='fs-6 text-gray-500 text-transform-unset'>
              Note: Please select a date range and click run to view the report
            </div>
          )}
          {flag == true && (
            <>
              <KTCardBody className='py-1 '>
                <div className='pt-3'>
                  <div className='card card-custom card-flush '>
                    <div className='card-body p-3 py-3'>
                      <div className='table-responsive padding-down reportTab'>
                        <table
                          id='kt_table_users'
                          className='table table-row-bordered table-row-gray-300 gy-4'
                        >
                          <>
                            <thead>
                              <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                                <>
                                  <th
                                    onClick={() => sorting5('business_unit_name')}
                                    className='  py-4'
                                  >
                                    Business Unit{' '}
                                    <span>
                                      {order5 === 'ASC' ? (
                                        <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                      ) : (
                                        <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                      )}
                                    </span>
                                  </th>
                                  <th onClick={() => sorting5('display_name')} className='py-4'>
                                    Consultant Name{' '}
                                    <span>
                                      {order5 === 'ASC' ? (
                                        <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                      ) : (
                                        <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                      )}
                                    </span>
                                  </th>
                                  <th onClick={() => sorting5('employee_type')} className='py-4'>
                                    Consultant Type{' '}
                                    <span>
                                      {order5 === 'ASC' ? (
                                        <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                      ) : (
                                        <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                      )}
                                    </span>
                                  </th>
                                  {/* <th onClick={() => sorting5('startingDate')} className='py-4'>
                                    Date Range <span className='fs-9'>(DD-MMM-YYYY)</span>{' '}
                                    <span>
                                      {order5 === 'ASC' ? (
                                        <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                      ) : (
                                        <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                      )}
                                    </span>
                                  </th> */}
                                  <th onClick={() => sorting5('employee_id')} className='  py-4'>
                                    Consultant Id{' '}
                                    <span>
                                      {order5 === 'ASC' ? (
                                        <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                      ) : (
                                        <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                      )}
                                    </span>
                                  </th>
                                  <th
                                    onClick={() => sorting5('adp_associate_id')}
                                    className='  py-4'
                                  >
                                    Associate ID{' '}
                                    <span>
                                      {order5 === 'ASC' ? (
                                        <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                      ) : (
                                        <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                      )}
                                    </span>
                                  </th>
                                  <th onClick={() => sorting5('placement_code')} className='  py-4'>
                                    Placement Code{' '}
                                    <span>
                                      {order5 === 'ASC' ? (
                                        <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                      ) : (
                                        <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                      )}
                                    </span>
                                  </th>
                                  <th onClick={() => sorting5('project_name')} className='  py-4'>
                                    Project Name{' '}
                                    <span>
                                      {order5 === 'ASC' ? (
                                        <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                      ) : (
                                        <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                      )}
                                    </span>
                                  </th>
                                  <th onClick={() => sorting5('project_status')} className='  py-4'>
                                    Project Status{' '}
                                    <span>
                                      {order5 === 'ASC' ? (
                                        <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                      ) : (
                                        <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                      )}
                                    </span>
                                  </th>
                                  <th onClick={() => sorting5('end_date')} className='  py-4'>
                                    End date{' '}
                                    <span>
                                      {order5 === 'ASC' ? (
                                        <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                      ) : (
                                        <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                      )}
                                    </span>
                                  </th>
                                  <th
                                    onClick={() =>
                                      sorting6('timesheet_total_data', 0, 'regular_hours_minutes')
                                    }
                                    className='  py-4'
                                  >
                                    Total Regular Hours{' '}
                                    <span>
                                      {order5 === 'ASC' ? (
                                        <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                      ) : (
                                        <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                      )}
                                    </span>
                                  </th>
                                  <th
                                    onClick={() =>
                                      sorting6('timesheet_total_data', 0, 'ot_hours_minutes')
                                    }
                                    className='  py-4'
                                  >
                                    Total OT Hours{' '}
                                    <span>
                                      {order5 === 'ASC' ? (
                                        <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                      ) : (
                                        <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                      )}
                                    </span>
                                  </th>
                                  <th
                                    onClick={() =>
                                      sorting6('timesheet_total_data', 0, 'total_hours_minutes')
                                    }
                                    className='  py-4'
                                  >
                                    Total Hours (Regular + OT ){' '}
                                    <span>
                                      {order5 === 'ASC' ? (
                                        <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                      ) : (
                                        <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                      )}
                                    </span>
                                  </th>

                                  {allTableData?.map((obj: any, index) => (
                                    <th className='  py-4' colSpan={3}>
                                      {moment(obj.timesheet_date, 'YYYY/MM/DD').format('dddd')}({' '}
                                      {moment(obj.timesheet_date).format('MM/DD/YYYY')}){' '}
                                      <span className='fs-9'>(MM/DD/YYYY)</span>
                                    </th>
                                  ))}
                                </>
                              </tr>

                              <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                                <th className='   py-4' colSpan={12}></th>
                                {allTableData?.map((obj: any, index) => (
                                  <>
                                    <>
                                      <th
                                        onClick={() =>
                                          sorting5('timesheet_data[0].regular_hours_minutes')
                                        }
                                        className='   py-4'
                                      >
                                        Regular Hours{' '}
                                        <span>
                                          {order5 === 'ASC' ? (
                                            <i
                                              className='bi bi-chevron-down ms-1 '
                                              role='button'
                                            ></i>
                                          ) : (
                                            <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                          )}
                                        </span>
                                      </th>
                                      <th
                                        onClick={() =>
                                          sorting5('timesheet_data[0].ot_hours_minutes')
                                        }
                                        className='   py-4'
                                      >
                                        Ot Hours{' '}
                                        <span>
                                          {order5 === 'ASC' ? (
                                            <i
                                              className='bi bi-chevron-down ms-1 '
                                              role='button'
                                            ></i>
                                          ) : (
                                            <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                          )}
                                        </span>
                                      </th>
                                      <th
                                        onClick={() => sorting5('timesheet_data[0].status')}
                                        className='   py-4'
                                      >
                                        Status{' '}
                                        <span>
                                          {order5 === 'ASC' ? (
                                            <i
                                              className='bi bi-chevron-down ms-1 '
                                              role='button'
                                            ></i>
                                          ) : (
                                            <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                          )}
                                        </span>
                                      </th>
                                    </>
                                  </>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {mainTableData?.length > 0 ? (
                                <>
                                  {mainTableData?.map((each: any, index: any) => (
                                    <>
                                      <tr
                                        className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                        key={index}
                                      >
                                        <td className=''>
                                          {each.business_unit_name ? each.business_unit_name : '-'}
                                        </td>
                                        <td className='text-capitalize'>
                                          {each.display_name
                                            ? each.display_name
                                            : each.full_name
                                            ? each.full_name
                                            : '-'}
                                        </td>
                                        <td className=''>
                                          {each.employee_type ? each.employee_type : '-'}
                                        </td>
                                        {/* <td className=''>{startingDate + ' To ' + endingDate}</td> */}
                                        <td className=''>
                                          {each.employee_id ? each.employee_id : '-'}
                                        </td>
                                        <td className=''>
                                          {each.adp_associate_id ? each.adp_associate_id : '-'}
                                        </td>
                                        <td className=''>
                                          {each.placement_code ? each.placement_code : '-'}
                                        </td>
                                        <td className='' style={{textTransform: 'capitalize'}}>
                                          {each.project_name ? each.project_name : '-'}
                                        </td>
                                        <td className=''>
                                          {each.project_status
                                            ? each.project_status == 'IN_PROGRESS'
                                              ? 'In Progress'
                                              : each.project_status == 'INITIATED'
                                              ? 'Initiated'
                                              : each.project_status == 'CANCELLED'
                                              ? 'Cancelled'
                                              : each.project_status == 'COMPLETED'
                                              ? 'Completed'
                                              : '-'
                                            : '-'}
                                        </td>
                                        <td className=''>
                                          {each.end_date
                                            ? moment(each.end_date).format('DD-MMM-YYYY')
                                            : each.cancel_date
                                            ? moment(each.cancel_date).format('DD-MMM-YYYY')
                                            : '-'}
                                        </td>

                                        <td className=''>
                                          {each.timesheet_total_data &&
                                          each.timesheet_total_data[0] &&
                                          each.timesheet_total_data[0]?.regular_hours_minutes
                                            ? each.timesheet_total_data[0]?.regular_hours_minutes
                                            : // ? each.timesheet_total_data[0]?.regular_hours_minutes?.slice(
                                              //     0,
                                              //     5
                                              //   )
                                              '0.00'}
                                        </td>
                                        <td className=''>
                                          {each.timesheet_total_data &&
                                          each.timesheet_total_data[0] &&
                                          each.timesheet_total_data[0].ot_hours_minutes
                                            ? // ? each.timesheet_total_data[0].ot_hours_minutes.slice(0, 5)
                                              each.timesheet_total_data[0].ot_hours_minutes
                                            : '0.00'}
                                        </td>
                                        <td className=''>
                                          {each.timesheet_total_data &&
                                          each.timesheet_total_data[0] &&
                                          each.timesheet_total_data[0].total_hours_minutes
                                            ? each.timesheet_total_data[0].total_hours_minutes
                                            : '0.00'}
                                        </td>
                                        <>
                                          {each.timesheet_data?.map((obj: any) => (
                                            <>
                                              <td className=''>
                                                {obj.ot_hours_minutes
                                                  ? // ? obj.ot_hours_minutes.slice(0, 5)
                                                    obj.ot_hours_minutes.toFixed(2)
                                                  : // : '00:00'}
                                                    '0.00'}
                                              </td>
                                              <td className=''>{obj.status ? obj.status : '-'}</td>
                                              <td className=''>
                                                {obj.regular_hours_minutes
                                                  ? // ? obj.regular_hours_minutes.slice(0, 5)
                                                    obj.regular_hours_minutes.toFixed(2)
                                                  : // : '00:00'}
                                                    '0.00'}
                                              </td>
                                            </>
                                          ))}
                                        </>
                                      </tr>
                                    </>
                                  ))}
                                </>
                              ) : (
                                ''
                              )}
                            </tbody>
                          </>
                        </table>
                        {(mainTableData?.length == null || mainTableData?.length == 0) && (
                          <div className='text-center  fw-bold text-gray-800'>
                            No Records found{' '}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <UsersListPagination />
              </KTCardBody>
              <div className='d-flex flex-stack py-2'>
                {mainTableData?.length > 0 ? (
                  <div>
                    <Container className='p-0'>
                      <Row className='align-items-center'>
                        <Col xs='auto' className='pe-0'>
                          {' '}
                          <label className='form-label fw-semibold'>Rows Per Page</label>
                        </Col>

                        <Col>
                          <select
                            name='perPage'
                            className='form-select'
                            onChange={onChange}
                            value={perPage}
                          >
                            {/* <option value='10'>10</option> */}
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='75'>75</option>
                            <option value={mainTableDataCount}>All</option>
                          </select>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                ) : null}

                <div className='pr-150'>
                  {mainTableData?.length > 0 ? (
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      pageCount={pages}
                      onPageChange={handlePageClick}
                      containerClassName={'pagination justify-content-right'}
                      activeClassName={'active'}
                      breakLabel={'...'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                    />
                  ) : null}
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
