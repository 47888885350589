import React, {useState, useRef, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import moment from 'moment'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {ToastContainer, toast} from 'react-toastify'
import clsx from 'clsx'
import {default as Apiservices} from '../../../../common/index'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Container, Row, Col} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import BackDrop from '../../../modules/apps/timesheetentry/ComponentBackdrop'
import EmployeePop from '../../../modules/apps/masters/modal/EmployeePop'
import {Tooltip} from '../../../modules/apps/timesheetentry/ComponentTooltip'

export default function WorkLocationReport(props: any) {
  const methods = useForm()
  const values = [true]
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [show, setShow] = useState(true)
  const [Reportsdata, setReports_Data] = useState<any | []>([])
  const [consultant_type, setConsultantType] = useState('')
  const [orgList, setOrgList] = useState<any | []>([])
  const [test, setTest] = useState(null)
  const [test1, setTest1] = useState(null)

  const handleClose = () => {
    setFullscreen(false)
    setShow(false)
    props.cancel(false)
    props.view_open(false)
    props.setViewOpen(false)
  }
  const validationSchema = Yup.object().shape({
    start_date: Yup.string().required('Date Of Joining is required').nullable(),
  })

  type UserSubmitForm = {
    employee_type: string
    org_name: any
    select_Year: any
    project_type: any
    start_date: any
  }
  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema),
  })
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [loading, setLoading] = useState(true)
  const [flag, setFlag] = useState(false)
  const [ReportData, setReportData] = useState<any | []>([])
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState([])
  const [selectMonth, setSelectMonth] = useState<any>([])
  const [selectConsultant, setSelectConsultant] = useState([])
  const [select_consultant, setSelectconsultant] = useState<any | []>([])
  const [country, setCountry] = useState<any | []>([])
  const [countries, setCountries] = useState<any | []>([])
  const [country1, setCountry1] = useState<any | []>([])
  const [candidate, setCandidate] = useState<any | []>([])
  const [candidateList, setCandidateList] = useState<any | []>([])
  const [useStateCountryId, setUseStateCountryId] = useState<any | []>([])
  const [states, setStates] = useState<any | []>([])
  const [state, setState] = useState<any | []>([])

  const typeaheadRef: any = useRef<HTMLInputElement>(null)
  const typeaheadRef2: any = useRef<HTMLInputElement>(null)
  const typeaheadRef3: any = useRef<HTMLInputElement>(null)
  const typeaheadRef1: any = useRef<HTMLInputElement>(null)
  const typeaheadRef4: any = useRef<HTMLInputElement>(null)
  const typeaheadRef5: any = useRef<HTMLInputElement>(null)
  const [validatedescription, setValidateDescription] = useState<any>(false)
  const [validateCountry, setValidateCountry] = useState<any>(false)

  const [BusinessUnitsList, setBusinessUnitsList] = useState<any | []>([])
  const [BusinessUnit, setBusinessUnit] = useState<any | []>([])
  const [countries1, setCountries1] = useState<any | []>([])
  const [years, setYears] = useState<any>([])
  const [selectYear, setSelectYear] = useState('')
  const [ProjectReportData, setProjectReportData] = useState<any | []>([])
  const [OrganizationReportData, setOrganizationReportData] = useState<any | []>([])

  const onSubmit = async (data: UserSubmitForm) => {
    setShowBackDrop(true)
    // const employeeValid: any = await Apiservices.employeeId()
    setShowBackDrop(false)
  }

  const options2: any = [
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    {
      label: 'Internal',
      value: 'Internal',
    },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'India Offshore',
      value: 'India Offshore',
    },
  ]
  const options3: any = [
    {
      label: 'January',
      value: '1',
    },
    {
      label: 'February',
      value: '2',
    },
    {
      label: 'March',
      value: '3',
    },
    {
      label: 'April',
      value: '4',
    },
    {
      label: 'May',
      value: '5',
    },
    {
      label: 'June',
      value: '6',
    },
    {
      label: 'July',
      value: '7',
    },
    {
      label: 'August',
      value: '8',
    },
    {
      label: 'September',
      value: '9',
    },
    {
      label: 'October',
      value: '10',
    },
    {
      label: 'November',
      value: '11',
    },
    {
      label: 'December',
      value: '12',
    },
  ]
  useEffect(() => {
    // handleConsultant()
    let currYr: any = new Date().getFullYear()
    setSelectYear(currYr)
    let pastYr = 2013
    while (currYr >= pastYr) {
      // setYears([...currYr]);
      years.push(currYr)
      currYr -= 1
    }
  }, [])
  useEffect(() => {
    get_business_units_list()
  }, [])
  const get_business_units_list = async () => {
    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      if (res.data.data.length > 0) {
        // setBusinessUnitsList(res.data.data)
        let obj = {name: 'Select All', value: 'All'}
        setBusinessUnitsList([obj, ...res.data.data])
      }
    }
  }
  const handleClick = async () => {
    setShowBackDrop(true)
    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined
    ) {
      setValidateDescription(true)
    } else {
      setValidateDescription(false)
    }

    if (
      countries == null ||
      countries == [] ||
      countries == '' ||
      countries?.length == 0 ||
      countries == undefined
    ) {
      setValidateCountry(true)
    } else {
      setValidateCountry(false)
    }

    // setValidateCountry
    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined ||
      countries == null ||
      countries == [] ||
      countries == '' ||
      countries?.length == 0 ||
      countries == undefined ||
      !getValues('start_date') ||
      test == null
    ) {
      console.log('if')
    } else {
      let business1 = BusinessUnit.map((val: any) => {
        return val.business_unit_id
      })
      let business2 = business1.filter((temp: any) => `${temp}`).join(',')
      let x =
        BusinessUnitsList &&
        BusinessUnitsList.length > 0 &&
        BusinessUnitsList.filter((temp: any) => temp.value != 'All' && temp.value != 'empty')

      let business3 = x?.map((val: any) => {
        return val.business_unit_id
      })
      let business4 = business3?.filter((temp: any) => `${temp}`).join(',')

      let state_id1 = states.map((value: any) => {
        return value.name
      })
      let state_id2 = state_id1.filter((temp: any) => `${temp}`).join(',')

      const article1 = {
        year: test
          ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
          : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
        month: selectMonth ? [Number(selectMonth)] : null, // optional  // (1=January, 12 = December )Pass null for all months data
        //   employee_type: stat2 ? stat1 : null,
        //   user_id: employee1 ? employee : null, // optional
        //   project_id: project1 ? project : null, // optional
        business_unit_id: business2 ? business1 : business4 ? business3 : null,
        //   is_placement_project: null,
        country: countries[0]?.name ? countries[0]?.name : null,
        state: state_id2 ? state_id1 : null,
      }
      const response: any = await Apiservices.get_location_wise_report(article1)
      if (response && response.isError == false) {
        setFlag(true)
        setProjectReportData(response.data)
      }
      setShowBackDrop(false)
    }
    setShowBackDrop(false)
  }

  const ref: any = useRef<HTMLInputElement>(null)

  const resetData = async (e: any) => {
    setValue('start_date', '')
    setCountry([])
    setCountries([])
    setStates([])
    setBusinessUnit([])
    setFlag(false)
    setConsultantType('')
    setValue('employee_type', '')
    setTest(null)
    setTest1(null)
    // typeaheadRef1.current.clear()
    setSelected([])
    setSelectMonth([])
    setSelectMonth(null)
    let dataSet = {
      project_type: '',
    }
    reset(dataSet)
    // typeaheadRef.current.clear()
    setSelectConsultant([])
    // typeaheadRef2.current.clear()
    setSelectconsultant([])
    // typeaheadRef3.current.clear()
    setCountries1([])
    setBusinessUnit([])
    typeaheadRef4.current.clear()

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })
    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }
  }

  const exporthandleClick = async (e: any) => {
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let x =
      BusinessUnitsList &&
      BusinessUnitsList.length > 0 &&
      BusinessUnitsList.filter((temp: any) => temp.value != 'All' && temp.value != 'empty')

    let business3 = x?.map((val: any) => {
      return val.business_unit_id
    })
    let business4 = business3?.filter((temp: any) => `${temp}`).join(',')

    let state_id1 = states.map((value: any) => {
      return value.name
    })
    let state_id2 = state_id1.filter((temp: any) => `${temp}`).join(',')
    const article1 = {
      year: test
        ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
        : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
      month: selectMonth ? [Number(selectMonth)] : null, // optional  // (1=January, 12 = December )Pass null for all months data
      //   employee_type: stat2 ? stat1 : null,
      //   user_id: employee1 ? employee : null, // optional
      //   project_id: project1 ? project : null, // optional
      business_unit_id: business2 ? business1 : business4 ? business3 : null,
      //   is_placement_project: null,
      country: countries[0]?.name ? countries[0]?.name : null,
      state: state_id2 ? state_id1 : null,
    }
    setShowBackDrop(true)

    const res1: any = await Apiservices.export_location_wise_report(article1)
    var link = document.createElement('a')
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', 'Downloaded File')
    link.href = res1?.data?.blobUrl
    document.body.appendChild(link)
    link.click()
    link.remove()

    setShowBackDrop(false)
  }
  let FilteredData = ReportData?.data?.filter(
    (each: any) =>
      each?.employee_id?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.employee_type?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.full_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.joining_date?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.leaving_date?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.emp_working_hours?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.total_hours?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.compliance_percentage?.toLowerCase()?.includes(search?.toLowerCase())
  )
  const singleDateSelection = async (date: any) => {
    setTest(date)
    setValue('start_date', date)
  }
  const singleDateSelection1 = async (date: any) => {
    setTest1(date)
  }
  const handleMonth = (month: any) => {
    setSelectMonth(month)
    setValidateDescription(false)
  }
  const handleCountries1 = async (country1: any) => {
    // if (country1 && country1.length >= 2) {
    const response: any = await Apiservices.getprojectbyname({country_name: country1})
    if (response?.data?.data !== null) {
      setCountry1(response && response.data && response.data.data ? response?.data?.data : '')
    }
    // }
  }

  const selectedCountries1 = async (state: any) => {
    setCountries1(state)
  }
  const user = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''

      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })

      return str
    }
  }
  const handleConsultant = async (consult_Name?: any) => {
    const response: any = await Apiservices.getconsultantbyname()
    setCandidate(response?.data?.data)
  }
  const handleFilterConsultant = async (consult_Name: any) => {
    // let filteredConsultant = candidate?.filter((each: any) =>
    //   each?.full_name?.toLowerCase()?.includes(consult_Name?.toLowerCase())
    // )
    // setCandidateList(filteredConsultant)
    const response: any = await Apiservices.getconsultantbyname()
    setCandidateList(response?.data?.data)
  }

  const selectedConsultant = async (consult_name: any) => {
    setSelectconsultant(consult_name)
  }

  const handleBusinessUnit = (selected: any) => {
    // setBusinessUnit(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = BusinessUnitsList.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setBusinessUnit(x)
          BusinessUnitsList.shift({
            name: 'Select All',
            value: 'All',
          })
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
        } else if (each.value == 'empty') {
          setBusinessUnit([])
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList)
          }
          BusinessUnitsList.pop({
            name: 'Deselect All',
            value: 'empty',
          })
        } else {
          setBusinessUnit(selected)
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          let check_drop = selected.find((item: any) => item && item.value && item.value == 'All')
          let check_drop1 = selected.find(
            (item: any) => item && item.value && item.value == 'empty'
          )
          if (!check_drop && !check_drop1) {
            let test = BusinessUnitsList.filter(
              (id: any) => !selected.some((x: any) => x.name == id.name)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              BusinessUnitsList.shift({
                name: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setBusinessUnit(selected)
      BusinessUnitsList.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
      if (!businessReset) {
        BusinessUnitsList.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }
  const handleCountries = async (country: any) => {
    if (country && country.length >= 2) {
      const response: any = await Apiservices.getcountriesbyname({country_name: country})
      if (response.data.data) {
        setCountry(response.data.data ? response.data.data : '')
      } else {
        setCountry([])
      }
    }
  }
  const selectedCountries = async (selected_country_name: any) => {
    if (selected_country_name.length == 0) {
      setStates([])
      setCountry([])
    }
    setCountries(selected_country_name)
    setUseStateCountryId(selected_country_name[0].id)
    if (selected_country_name[0]) {
      setValidateCountry(false)
    }
    const response: any = await Apiservices.getstatebyname({
      country_id: selected_country_name[0].id,
    })
    if (response?.data?.data) {
      setState(response.data.data ? response.data.data : '')
    } else {
      setState([])
    }
  }

  const handleStates = async (state: any) => {
    if (state && state.length >= 1) {
      if (useStateCountryId) {
        const response: any = await Apiservices.getstatebyname({country_id: useStateCountryId})
        if (response?.data?.data) {
          setState(response.data.data ? response.data.data : '')
        } else {
          setState([])
        }
      }
    }
  }
  const selectedStates = async (selected_state_name: any) => {
    setStates(selected_state_name)
  }
  return (
    <>
      <Modal show={show} fullscreen={fullscreen} onHide={() => handleClose()}>
        <Modal.Header closeButton className='p-3 bg-light'>
          <Modal.Title>Work Location Report</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-3 bg-light'>
          <div className='d-flex justify-content-between align-items-center'>
            <div> </div>
          </div>
          <BackDrop showBackDrop={showBackDrop} />
          {/* <form onSubmit={handleSubmit(onSubmit)}> */}
          <BackDrop showBackDrop={showBackDrop} />
          {/* {loading ? ( */}
          <>
            {' '}
            <div className='card card-custom card-flush '>
              <div className='card-body p-2 py-3'>
                <div className='row gy-3 align-items-center py-2'>
                  <div className='col-xs-12 col-md-3 col-lg-3'>
                    <label className='form-label fw-semibold'>Select Year *</label>
                    <DatePicker
                      selected={test}
                      {...register('start_date', {
                        required: true,
                      })}
                      onChange={(date: any) => singleDateSelection(date)}
                      placeholderText='Select Year'
                      className={clsx('form-control')}
                      dateFormat='yyyy'
                      showYearPicker
                      // required
                    />
                    {errors.start_date &&
                      errors.start_date.type === 'required' &&
                      !getValues('start_date') && (
                        <span className='error text-danger mb-0'>Year is required</span>
                      )}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg '>
                    <label className='form-label '>Select Month *</label>
                    {/* <DatePicker
                          selected={test1}
                          onChange={(date: any) => singleDateSelection1(date)}
                          //   customInput={<ExampleCustomInput />}
                          placeholderText='Select Month'
                          className={clsx('form-control')}
                          dateFormat='MMM'
                          // calendarStartDay={1}
                          showMonthYearPicker
                          // required
                        /> */}

                    <select
                      className={clsx('form-select')}
                      placeholder='Select Month'
                      {...register('project_type', {
                        required: true,
                        onChange: (e) => {
                          // setSelectMonth(e.target.value)
                          handleMonth(e.target.value)
                        },
                      })}
                      aria-label='Default select example'
                    >
                      <option value=''>Select Month</option>
                      <option value='1'>January</option>
                      <option value='2'>February</option>
                      <option value='3'>March</option>
                      <option value='4'>April</option>
                      <option value='5'>May</option>
                      <option value='6'>June</option>
                      <option value='7'>July</option>
                      <option value='8'>August</option>
                      <option value='9'>September</option>
                      <option value='10'>October</option>
                      <option value='11'>November</option>
                      <option value='12'>December</option>
                    </select>
                    {validatedescription && (
                      <span className='error text-danger mb-0'>Month is required</span>
                    )}

                    {/* <div className='invalid-feedback'>{errors.employee_type?.message}</div> */}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Country *</label>
                    <Typeahead
                      id='basic-typeahead-single'
                      // disabled={isView}
                      onChange={(e) => selectedCountries(e)}
                      onInputChange={(e) => handleCountries(e)}
                      options={country}
                      placeholder='Select Country'
                      // disabled={AllFieldsToDisabled}
                      selected={countries}
                      labelKey={(country: any) => user(country.name)}
                      //   inputProps={{
                      //     // required: true
                      //     required: proj_type == '1' ? true : false,
                      //   }}
                    >
                      {({onClear, selected}) => (
                        <div className='rbt-aux'>
                          {!!selected.length && (
                            // <ClearButton disabled={AllFieldsToDisabled} onClick={onClear} />
                            <ClearButton onClick={onClear} />
                          )}
                        </div>
                      )}
                    </Typeahead>
                    {validateCountry && (
                      <span className='error text-danger mb-0'>Country is required</span>
                    )}

                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>

                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>State</label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(e) => {
                        selectedStates(e)
                        typeaheadRef5.current.toggleMenu()
                      }}
                      onInputChange={(e) => handleStates(e)}
                      options={state}
                      ref={typeaheadRef5}
                      placeholder='Select State'
                      selected={states}
                      labelKey={(state: any) => user(state.name)}
                      //   inputProps={{
                      //     required: proj_type == '1' ? true : false,
                      //     // required: true
                      //   }}
                    >
                      {({onClear, selected}) => (
                        <div className='rbt-aux'>
                          {!!selected.length && (
                            // <ClearButton disabled={AllFieldsToDisabled} onClick={onClear} />
                            <ClearButton onClick={onClear} />
                          )}
                        </div>
                      )}
                    </Typeahead>

                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Business Unit</label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(e) => {
                        // setBusinessUnit(e)
                        handleBusinessUnit(e)
                        typeaheadRef4.current.toggleMenu()
                      }}
                      options={BusinessUnitsList}
                      ref={typeaheadRef4}
                      placeholder='Select Business Unit'
                      selected={BusinessUnit}
                      labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                      //   inputProps={{required: true}}
                    ></Typeahead>
                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>

                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button className='btn btn-sm btn-primary' type='submit' onClick={handleClick}>
                      Run
                    </button>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={resetData}
                    >
                      Reset
                    </button>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      className='btn btn-light-primary   btn-sm'
                      onClick={exporthandleClick}
                    >
                      Export
                      {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
          {/* ) : (
              <h1>load</h1>
            )} */}
          {/* </form> */}
          {flag == false && (
            <div className='fs-6 text-gray-500 text-transform-unset'>
              Note:Please select year, month, country and click run to view the report.
            </div>
          )}
          {flag == true && (
            <>
              <div className='pt-3'>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-3 py-3'>
                    <div className='table-responsive padding-down reportTab'>
                      <table
                        id='kt_table_users'
                        className='table table-row-bordered table-row-gray-300 gy-4'
                      >
                        <>
                          <thead>
                            <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                              <th className='  py-4'>Country </th>
                              <th className='  py-4'>State </th>
                              <th className='  py-4'>Month</th>
                              <th className='  py-4'>Organization</th>
                              <th className='  py-4'>Business Unit</th>
                              <th className='  py-4'>No. of consultants</th>
                              <th className='  py-4'>No. of Projects</th>
                              <th className='  py-4'>Billed Hours</th>
                              <th className='  py-4'>Revenue</th>
                              <th className='  py-4'>Contribution %</th>
                            </tr>
                          </thead>

                          <tbody>
                            {ProjectReportData?.length > 0 ? (
                              <>
                                {ProjectReportData?.map((each: any, index: any) => {
                                  const sumofhour = ProjectReportData?.reduce(function (
                                    acc: any,
                                    data: any
                                  ) {
                                    return acc + Number(data?.total_revenue)
                                  },
                                  0)
                                  return (
                                    <tr
                                      className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                      key={index}
                                    >
                                      <td className=''>{each.country ? each.country : '-'}</td>
                                      <td className=''>{each.stateName ? each.stateName : '-'}</td>
                                      <td className=''>
                                        {each.monthName
                                          ? moment(each.monthName, 'MMMM').format('MMM')
                                          : '-'}{' '}
                                        {moment(test, 'ddd MMM DD YYYY').format('YYYY')}
                                      </td>
                                      <td className=''>{localStorage.getItem('org_name')}</td>
                                      <td className=''>
                                        {each.business_unit_name ? each.business_unit_name : '-'}
                                      </td>
                                      <td className=''>
                                        {each.total_consultants ? each.total_consultants : '-'}
                                      </td>
                                      <td className=''>
                                        {each.total_projects ? each.total_projects : '-'}
                                      </td>
                                      <td className=''>
                                        {each.total_logged_hours ? each.total_logged_hours : '-'}
                                      </td>
                                      <td>
                                        {'$' +
                                          Number(each.total_revenue).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                      </td>
                                      <td className=''>
                                        {each.total_revenue
                                          ? sumofhour > 0
                                            ? ((each.total_revenue / sumofhour) * 100).toFixed(2)
                                            : 0
                                          : '-'}
                                      </td>
                                    </tr>
                                  )
                                })}
                              </>
                            ) : (
                              ''
                            )}
                          </tbody>
                        </>
                      </table>
                      {(ProjectReportData?.length == null || ProjectReportData?.length == 0) && (
                        <div className='text-center  fw-bold text-gray-800'>No Records found </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-stack py-2'></div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
